import { type UseModalState, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { ProgressDialog } from "@src/appV2/lib";
import {
  APP_V2_APP_EVENTS,
  APP_V2_USER_EVENTS,
  logError,
  logEvent,
} from "@src/appV2/lib/analytics";
import { useRef, useState } from "react";

import {
  type PostCreateTimeclockComplianceProofUploadUrlResponse,
  TimeclockComplianceClockAction,
  TimeclockComplianceResourceType,
  useCreateTimeclockComplianceProofUploadUrl,
} from "../api/useCreateShiftTimeclockComplianceProofUploadUrl";
import { type HcfTimeclockComplianceVideoConfig } from "../ShiftState/types";
import { ClockActionPictureDialogEvent } from "./ClockActionPictureDialogEvent";
import { TimeclockCameraAccessDialog } from "./dialogs/CameraAccessDialog";
import { NfcSuccessDialog } from "./dialogs/NfcSuccessDialog";
import { RecordVideoDialog } from "./dialogs/RecordVideoDialog";
import { TimeclockComplianceVideoPolicyDialog } from "./dialogs/VideoPolicyDialog";
import { VideoUploadErrorDialog } from "./dialogs/VideoUploadErrorDialog";
import { getPermissions, uploadTimeclockProofVideoToS3 } from "./utils";

export interface ComplianceProofVideo {
  bucketName: string;
  fileKey: string;
}

export interface TimeclockComplianceVideoDialogProps {
  modalState: UseModalState;
  isNfcEnabled: boolean;
  facilityName: string;
  timeclockLocation: string | undefined;
  timeclockPin: string | undefined;
  workplaceId: string;
  shiftId: string;
  workerId: string;
  clockAction: "clock-in" | "clock-out";
  videoConfig: HcfTimeclockComplianceVideoConfig | undefined;

  onSuccess: (video: ComplianceProofVideo) => void;
  onCancel: () => void;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function TimeclockComplianceVideoDialog(props: TimeclockComplianceVideoDialogProps) {
  const {
    modalState,
    isNfcEnabled,
    facilityName = "The facility",
    timeclockLocation,
    timeclockPin,
    workplaceId,
    shiftId,
    workerId,
    clockAction,
    videoConfig,
    onSuccess,
    onCancel,
  } = props;

  const [currentPage, setCurrentPage] = useState<
    "policy" | "record-video" | "nfc-success-dialog" | "error"
  >("policy");
  const [videoErrorText, setVideoErrorText] = useState<string>();
  const videoBlobRef = useRef<Blob>();
  const [isUploading, setIsUploading] = useState(false);
  const camereAccessModalState = useModalState();
  const { mutateAsync: getS3PresignedUploadUrl } = useCreateTimeclockComplianceProofUploadUrl();
  const videoResourceRef = useRef<ComplianceProofVideo>();

  const logData = {
    video: true,
    isNfcEnabled,
    action: clockAction,
    shiftId,
    workerId,
    workplaceId,
    timeclockLocation,
    pinLength: timeclockPin ? timeclockPin.length : undefined,
  };

  const logTimeclockEvent = (
    eventAction: ClockActionPictureDialogEvent,
    meta?: Record<string, any>
  ) => {
    logEvent(APP_V2_USER_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_EVENT, {
      ...logData,
      ...meta,
      eventAction,
    });
  };

  const continueToRecording = async (source: "main-dialog" | "help-dialog") => {
    const { camera, microphone } = await getPermissions();

    if (!camera || !microphone) {
      camereAccessModalState.openModal();
      return;
    }

    logTimeclockEvent(ClockActionPictureDialogEvent.POLICY_ACCEPTED);
    if (source === "help-dialog") {
      logTimeclockEvent(ClockActionPictureDialogEvent.USED_FALLBACK_POLICY);
    }

    setCurrentPage("record-video");
  };

  const onUploadSettled = (isSuccess: boolean) => {
    if (!isSuccess) {
      setCurrentPage("error");
      return;
    }

    if (isNfcEnabled) {
      setCurrentPage("nfc-success-dialog");
    } else {
      onSuccess(videoResourceRef.current!);
    }
  };

  const startVideoUpload = async () => {
    const videoBlob = videoBlobRef.current;

    if (!videoBlob?.size) {
      logTimeclockEvent(ClockActionPictureDialogEvent.VIDEO_RECORDING_FAILED, {
        error: "Missing videoBlob at upload",
        hasVideoBlob: isDefined(videoBlob),
        blobSize: videoBlob?.size,
      });
      setVideoErrorText("There was an error with the video. Please try again.");
      onUploadSettled(false);
      return;
    }

    logTimeclockEvent(ClockActionPictureDialogEvent.UPLOAD_START, {
      blobSize: videoBlob.size,
    });

    setIsUploading(true);

    let uploadUrlResponse: PostCreateTimeclockComplianceProofUploadUrlResponse;
    try {
      uploadUrlResponse = await getS3PresignedUploadUrl({
        shiftId,
        clockAction:
          clockAction === "clock-in"
            ? TimeclockComplianceClockAction.CLOCK_IN
            : TimeclockComplianceClockAction.CLOCK_OUT,
        resourceType: TimeclockComplianceResourceType.MP4,
      });

      if (!uploadUrlResponse.data) {
        setVideoErrorText(
          "Something went wrong while preparing video upload. Please ensure you have reliable connectivity and retry upload. It may help to connect to the facility's Wi-Fi."
        );
        onUploadSettled(false);
        setIsUploading(false);
        return;
      }
    } catch (error: unknown) {
      logTimeclockEvent(ClockActionPictureDialogEvent.UPLOAD_FAILURE, {
        source: "presigned-upload-url",
        blobSize: videoBlob.size,
        error: error instanceof Error ? error.message : "Unknown error",
      });
      logError(APP_V2_APP_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_ERROR, {
        error,
        metadata: { logData, location: "presigned-upload-url" },
      });

      setVideoErrorText(
        "Something went wrong while preparing video upload. Please ensure you have reliable connectivity and retry upload. It may help to connect to the facility's Wi-Fi."
      );
      onUploadSettled(false);
      setIsUploading(false);
      return;
    }

    const { uploadUrl, bucketName, fileKey } = uploadUrlResponse.data.attributes;
    const { isSuccess } = await uploadTimeclockProofVideoToS3({
      uploadUrl,
      videoBlob,
      logData: {
        ...logData,
        clockAction,
        bucketName,
        fileKey,
        uploadUrlLength: uploadUrl.length,
        blobSize: videoBlob.size,
      },
    });

    if (isSuccess) {
      videoResourceRef.current = { bucketName, fileKey };
    } else {
      setVideoErrorText(
        "Something went wrong. Please ensure you have reliable connectivity and retry upload. It may help to connect to the facility's Wi-Fi."
      );
    }

    setIsUploading(false);
    onUploadSettled(isSuccess);
  };

  const onRetryVideoUpload = () => {
    if (videoBlobRef.current !== undefined && videoBlobRef.current.size > 0) {
      void startVideoUpload();
      logTimeclockEvent(ClockActionPictureDialogEvent.REUPLOAD_VIDEO);
      return;
    }

    setCurrentPage("record-video");
    videoBlobRef.current = undefined;
    logTimeclockEvent(ClockActionPictureDialogEvent.RETAKE_VIDEO);
  };

  return modalState.modalIsOpen ? (
    <>
      {currentPage === "policy" && (
        <TimeclockComplianceVideoPolicyDialog
          facilityName={facilityName}
          clockAction={clockAction}
          timeclockLocation={timeclockLocation}
          timeclockPin={timeclockPin}
          onContinue={continueToRecording}
          onCancel={() => {
            onCancel();
            logTimeclockEvent(ClockActionPictureDialogEvent.POLICY_CANCELLED);
          }}
        />
      )}
      {currentPage === "record-video" && (
        <RecordVideoDialog
          videoConfig={videoConfig}
          logData={logData}
          videoConfirmedCallback={(blob) => {
            videoBlobRef.current = blob;
            void startVideoUpload();
          }}
          onClose={() => {
            videoBlobRef.current = undefined;
            setCurrentPage("policy");
          }}
        />
      )}
      {currentPage === "nfc-success-dialog" && (
        <NfcSuccessDialog
          isVideo
          onContinue={() => {
            onSuccess(videoResourceRef.current!);
          }}
        />
      )}
      {currentPage === "error" && (
        <VideoUploadErrorDialog
          errorText={videoErrorText ?? "Some unexpected error occured while submitting the video."}
          isLoading={isUploading}
          canRetryUpload={Boolean(videoBlobRef.current?.size)}
          onRetry={onRetryVideoUpload}
          onClose={onCancel}
        />
      )}
      <TimeclockCameraAccessDialog
        modalState={camereAccessModalState}
        onContinue={camereAccessModalState.closeModal}
        onCancel={camereAccessModalState.closeModal}
      />
      <ProgressDialog modalIsOpen={isUploading} messageContent="Uploading video" />
    </>
  ) : null;
}
