import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib/Dialogs/Common/Dialog";

export function ConfirmClockVideoDeletionDialog(props: {
  modalState: UseModalState;
  onCancel: () => void;
  onContinue: () => void;
}) {
  const { modalState, onCancel, onContinue } = props;

  return (
    <CommonDialog
      disableActionsSpacing
      modalState={modalState}
      scroll="body"
      actions={
        <>
          <Button fullWidth variant="contained" onClick={onContinue}>
            Continue
          </Button>
          <Button fullWidth variant="text" onClick={onCancel}>
            Never mind
          </Button>
        </>
      }
      title="Are you sure?"
      onClose={() => {
        onCancel();
      }}
    >
      <Stack spacing={2} alignItems="center">
        <Text>You will delete the video you just recorded and need to record another.</Text>
      </Stack>
    </CommonDialog>
  );
}
