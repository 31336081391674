export enum ClockActionPictureDialogEvent {
  FLOW_STARTED = "Flow Started",
  FLOW_COMPLETED = "Flow Completed",

  POLICY_ACCEPTED = "Policy Accepted",
  POLICY_CANCELLED = "Policy Cancelled",

  USED_FALLBACK_POLICY = "Used Fallback Policy",

  RETAKE_PICTURE = "Retake Picture",
  PICTURE_TAKING_FAILED = "Picture Taking Failed",

  RECORDING_START = "Recording Start",
  RECORDING_CANCEL = "Recording Cancel",
  RECORDING_DONE = "Recording Done",

  RETAKE_VIDEO = "Retake Video",
  REUPLOAD_VIDEO = "Reupload Video",
  VIDEO_RECORDING_FAILED = "Video Recording Failed",

  ERROR_DIALOG_OPENED = "Error Dialog Opened",
  ERROR_DIALOG_RETRY = "Error Dialog Retry",
  ERROR_DIALOG_CANCELLED = "Error Dialog Cancelled",

  UPLOAD_START = "Upload Start",
  UPLOAD_SUCCESS = "Upload Success",
  UPLOAD_FAILURE = "Upload Failure",
}
