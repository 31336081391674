import { Span, Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib/Dialogs/Common/Dialog";

export function TimeclockRecordingHelpDetailsDialog(props: {
  modalState: UseModalState;
  onCancel: () => void;
  onContinue: () => void;
}) {
  const { modalState, onCancel, onContinue } = props;

  return (
    <CommonDialog
      disableActionsSpacing
      modalState={modalState}
      scroll="body"
      actions={
        <>
          <Button fullWidth variant="contained" onClick={onContinue}>
            Continue
          </Button>
          <Button fullWidth variant="text" onClick={onCancel}>
            Go back
          </Button>
        </>
      }
      title="Need help?"
      onClose={() => {
        onCancel();
      }}
    >
      <Stack spacing={2} alignItems="center">
        <Text>
          If you are not able to use the workplace&apos;s time clock, please seek help from facility
          staff. If you still can&apos;t resolve the problem, please record a selfie video in front
          of the time clock explaining why you cannot use it.
        </Text>
        <Text>
          Videos will be reviewed by facility admin.{" "}
          <Span color="red">Failure to use the time clock may result in DNR or pay reversal.</Span>
        </Text>
      </Stack>
    </CommonDialog>
  );
}
