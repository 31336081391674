import { CameraSource } from "@capacitor/camera";
import { type UseModalState } from "@clipboard-health/ui-react";
import { type CameraPermissionDeniedError } from "@src/appV2/Accounts/DocumentDetails/errors";
import { useTakePhoto } from "@src/appV2/Accounts/DocumentDetails/hooks/useTakePhoto";
import { type UploadFileData } from "@src/appV2/Accounts/DocumentDetails/types";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useEffect, useState } from "react";

import { ClockActionPictureDialogEvent } from "./ClockActionPictureDialogEvent";
import { ErrorDialog } from "./dialogs/ErrorDialog";
import { NfcSuccessDialog } from "./dialogs/NfcSuccessDialog";
import { PolicyDialog } from "./dialogs/PolicyDialog";
import { ReviewPicturePage } from "./dialogs/ReviewPicturePage";

const environment = process.env.REACT_APP_ENVIRONMENT_NAME;

export interface ClockActionPictureDialogProps {
  modalState: UseModalState;
  isNfcEnabled: boolean;
  facilityName: string;
  timeclockLocation: string;
  timeclockPin: string | undefined;
  workplaceId: string;
  shiftId: string;
  workerId: string;
  clockAction: "clock-in" | "clock-out";

  onSuccess: () => void;
  onCancel: () => void;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function ClockActionPictureDialog(props: ClockActionPictureDialogProps) {
  const {
    modalState,
    isNfcEnabled,
    facilityName = "The facility",
    timeclockLocation,
    timeclockPin,
    workplaceId,
    shiftId,
    workerId,
    clockAction,
    onSuccess,
    onCancel,
  } = props;
  const [currentPage, setCurrentPage] = useState<
    "policy" | "review-picture" | "nfc-success-dialog" | "error"
  >("policy");
  const [photoData, setPhotoData] = useState<UploadFileData>();
  const [photoError, setPhotoError] = useState<Error | CameraPermissionDeniedError>();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (clockAction === "clock-out") {
      setCurrentPage("policy");
    }
  }, [clockAction]);

  const logData = {
    isNfcEnabled,
    action: clockAction,
    shiftId,
    workerId,
    workplaceId,
    timeclockLocation,
    pinLength: timeclockPin ? timeclockPin.length : undefined,
  };

  const takePicture = useTakePhoto({
    source: CameraSource.Camera,
    onSuccess: (result) => {
      setIsLoading(false);
      setPhotoError(undefined);
      setPhotoData(result);

      setCurrentPage("review-picture");
    },
    onError: (error) => {
      setIsLoading(false);
      setPhotoError(error);

      logEvent(APP_V2_USER_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_EXPERIMENT_EVENT, {
        ...logData,
        eventAction: ClockActionPictureDialogEvent.PICTURE_TAKING_FAILED,
        error: error instanceof Error ? error.message : "Unknown error",
      });

      setCurrentPage("error");
    },
    photoOptions: {
      height: 1200,
    },
  });

  let uploadFileName;
  if (workplaceId && shiftId && workerId && photoData) {
    const hourMinuteSeconds = new Date().toISOString().slice(11, 19);
    const fileName = `${workplaceId}/w-${workerId}-s-${shiftId}-${clockAction}-${hourMinuteSeconds}.${photoData.extension}`;
    uploadFileName = environment ? `${environment}/${fileName}` : `${fileName}`;
  }

  return modalState.modalIsOpen ? (
    <>
      {currentPage === "policy" && (
        <PolicyDialog
          isLoading={isLoading}
          facilityName={facilityName}
          clockAction={clockAction}
          timeclockLocation={timeclockLocation}
          timeclockPin={timeclockPin}
          onContinue={async () => {
            setIsLoading(true);
            takePicture();

            logEvent(APP_V2_USER_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_EXPERIMENT_EVENT, {
              ...logData,
              eventAction: ClockActionPictureDialogEvent.POLICY_ACCEPTED,
            });
          }}
          onCancel={() => {
            setIsLoading(false);

            setCurrentPage("policy");
            onCancel();

            logEvent(APP_V2_USER_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_EXPERIMENT_EVENT, {
              ...logData,
              eventAction: ClockActionPictureDialogEvent.POLICY_CANCELLED,
            });
          }}
        />
      )}
      {currentPage === "review-picture" && (
        <ReviewPicturePage
          imageBlob={photoData?.blob}
          imageUrl={photoData?.dataUrlBase64}
          uploadFileName={uploadFileName}
          logData={logData}
          onContinue={() => {
            if (isNfcEnabled) {
              setCurrentPage("nfc-success-dialog");
            } else {
              onSuccess();
            }

            logEvent(APP_V2_USER_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_EXPERIMENT_EVENT, {
              ...logData,
              eventAction: ClockActionPictureDialogEvent.FLOW_COMPLETED,
            });
          }}
          onCancel={() => {
            takePicture();

            logEvent(APP_V2_USER_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_EXPERIMENT_EVENT, {
              ...logData,
              eventAction: ClockActionPictureDialogEvent.RETAKE_PICTURE,
            });
          }}
        />
      )}
      {currentPage === "nfc-success-dialog" && <NfcSuccessDialog onContinue={onSuccess} />}
      {currentPage === "error" && (
        <ErrorDialog
          isLoading={isLoading}
          error={photoError}
          onRetry={() => {
            setIsLoading(true);
            takePicture();

            logEvent(APP_V2_USER_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_EXPERIMENT_EVENT, {
              ...logData,
              eventAction: ClockActionPictureDialogEvent.ERROR_DIALOG_RETRY,
            });
          }}
          onClose={() => {
            if (photoData) {
              setCurrentPage("review-picture");
            } else {
              setCurrentPage("policy");
            }

            logEvent(APP_V2_USER_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_EXPERIMENT_EVENT, {
              ...logData,
              eventAction: ClockActionPictureDialogEvent.ERROR_DIALOG_CANCELLED,
            });

            onCancel();
          }}
        />
      )}
    </>
  ) : null;
}
