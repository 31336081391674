import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { isPlatform } from "@ionic/core";
import { Diagnostic } from "@ionic-native/diagnostic";
import { Button } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib/Dialogs/Common/Dialog";

export function TimeclockCameraAccessDialog(props: {
  modalState: UseModalState;
  onCancel: () => void;
  onContinue: () => void;
}) {
  const { modalState, onCancel, onContinue } = props;

  return (
    <CommonDialog
      disableActionsSpacing
      modalState={modalState}
      scroll="body"
      actions={
        <>
          <Button
            fullWidth
            variant="contained"
            onClick={async () => {
              if (isPlatform("capacitor")) {
                await Diagnostic.switchToSettings();
              }

              onContinue();
            }}
          >
            Open settings
          </Button>
          <Button fullWidth variant="text" onClick={onCancel}>
            Go back
          </Button>
        </>
      }
      title="Camera access needed"
      onClose={() => {
        onCancel();
      }}
    >
      <Text>
        We can&apos;t proceed without camera and microphone access. Please enable both settings in
        your device settings for the Clipboard Health app to continue.
      </Text>
    </CommonDialog>
  );
}
